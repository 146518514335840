import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';

import Truncate from 'react-truncate';

export const FeaturedPost = ({ postImage, title, postExcerpt, slug, postDate, date }) => {
  return (
    <Link
      to={`/insights/${slug && slug.current}`}
      className="relative w-full h-96 block lg:h-blogSideCard"
    >
      <div className="z-0 lg:absolute h-full w-full top-0 left-0">
        {postImage && postImage.asset && (
          <Image {...postImage} className="absolute top-0 left-0 w-full h-full object-cover" />
        )}
      </div>
      <div className="absolute top-0 left-0 w-full h-full p-4 bg-black bg-opacity-60 text-white justify-between grid grid-col-1 grid-rows-2 z-10 lg:p-8">
        <div>
          <h4 className="uppercase text-sm font-sans tracking-wide inline-block">
            Latest Insight
            <span className="h-0.5 w-full bg-logic-teal block" />
          </h4>
        </div>
        <div className="self-end max-w-lg">
          <h4 className="uppercase text-sm font-sans">{date}</h4>
          <h2 className="font-sans text-xl my-2 pr-16 lg:text-3xl xl:text-4xl">{title}</h2>
          <Truncate lines={2} ellipsis={<span>...</span>}>
            {postExcerpt}
          </Truncate>
          <span className="underline font-sans mt-4 block">Read More</span>
        </div>
      </div>
    </Link>
  );
};
