import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';

export const PostCard = ({ postImage, title, postExcerpt, slug, postDate, date }) => {
  return (
    <Link className="grid grid-cols-10 py-8" to={`/insights/${slug && slug.current}`}>
      <div className="col-start-1 col-end-7 lg:prose xl:prose-lg">
        <h4 className="uppercase text-sm font-sans">{date}</h4>
        <h2 className="font-sans text-xl my-2">{title}</h2>
        <p className="hidden lg:block">{postExcerpt}</p>
        <span className="underline font-sans">Read More</span>
      </div>
      <div className="col-start-8 col-end-11">
        <div className="aspect-h-1 aspect-w-1 w-full">
          {postImage && postImage && (
            <Image {...postImage} className="absolute h-full w-full object-cover" />
          )}
        </div>
      </div>
    </Link>
  );
};
