import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks
import { useAppContext } from '../state';

import { FeaturedPost } from '../components/blog/featuredPost';
import { PostCard } from '../components/blog/postCard';

const InsightsPage = ({ data: { featuredPost, allPosts } }) => {
  const { setReverseNav, setHideFooter } = useAppContext();

  useEffect(() => {
    document.documentElement.classList.remove('post');
      document.documentElement.classList.remove('themeDark');
      document.title = "Insights | Logiq Group";
    setReverseNav(false);
    setHideFooter(false);
  }, [setReverseNav, setHideFooter]);

  return (
    <div className="pt-32 pb-32 grid grid-cols-14 gap-y-2 lg:pt-blogOffset">
      {/* Featured Post */}
      <div className="col-start-2 col-end-14 lg:col-end-8 lg:col-start-1 lg:pl-24 lg:pr-10">
        <div className="transition-all relative lg:sticky lg:top-headerOffset lg:h-blogSideBar lg:flex lg:items-center">
          <FeaturedPost {...featuredPost.edges[0].node} />
        </div>
      </div>
      {/* All Posts */}
      <div className="col-start-2 col-end-14 lg:col-start-8 grid divide-y divide-logic-navy lg:col-end-15 lg:pr-24 lg:pl-10">
        {allPosts.edges.map(({ node }) => (
          <PostCard {...node} />
        ))}
      </div>
    </div>
  );
};

export default InsightsPage;

export const pageQuery = graphql`
  query blogPortalQuery {
    featuredPost: allSanityBlogPost(sort: { order: DESC, fields: date }, limit: 1) {
      edges {
        node {
          title
          date(locale: "NZ", formatString: "DD MMMM YYYY")
          postExcerpt
          postImage {
            ...ImageWithPreview
          }
          postDate: _createdAt(locale: "NZ", formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
    allPosts: allSanityBlogPost(sort: { order: DESC, fields: date }, skip: 1) {
      edges {
        node {
          title
          date(locale: "NZ", formatString: "DD MMMM YYYY")
          postExcerpt
          postImage {
            ...ImageWithPreview
          }
          postDate: _createdAt(locale: "NZ", formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
  }
`;
